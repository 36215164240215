// Custom scripts
import {
  aListesis,
  aDiskDegen,
  aModic,
  aDiskDirection,
  aDiskMigration,
  aStenosis,
  aFractureSite,
  aFractureDepth,
  aFractureEdema,
  aFractureProminence,
  aFractureEndplate,
  aSignal,
  aLaminectomy,
  aStabilisation,
} from './MR_spine_arrays';

import {
  aSpineSag,
  aSpineCor,
  aDiskPat,
  aADArthrosis,
  aAddFinds,
  aFacetArthrosis,
  aUncoArthrosis,
} from './MR_spine_C_arrays';

import {
  indent,
  br,
  aSize,
  aVertebrae,
  aSpine,
} from '../scripts/GeneralArrays';
import {
  formatArrayToText,
  capitelizeFirstLetter,
  getFirstVertebraFromSegment,
  compareNumbers,
  getDiskDirection,
} from '../scripts/GeneralFunctions';

export const generateReport = props => {
  const { exam, indication, contrast, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: '',
    },
    description2: {
      forUI: [],
      forPaste: '',
    },
    conclusion1: {
      forUI: [],
      forPaste: '',
    },
    conclusion2: {
      forUI: [],
      forPaste: '',
    },
  };

  const importDescription1 = text => {
    report.description1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description1.forPaste += '')
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = text => {
    report.description2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description2.forPaste += '')
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = text => {
    report.conclusion1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion1.forPaste += '')
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = text => {
    report.conclusion2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion2.forPaste += '')
      : (report.conclusion2.forPaste += indent + text + br);
  };

  let laminectomyDescription = '';
  let laminectomyConclusion = '';

  // Komprese
  let compressions = props.compressions;

  let arthrosisDecompConclusion = '';

  let {
    boneLesions,
    laminectomy,
    stabilisation,
    customSegment,
    customVertebra,
  } = props;
  let boneLesionsConclusion = '';
  let stabilisationConclusion = '';
  let customSegmentConclusion = '';
  let customVertebraConclusion = '';

  const { ADArthrosis } = props.general;

  // Páteř segmenty
  let segments = props.segments.filter(
    item =>
      item.listesis > 0 ||
      item.diskDegen > 0 ||
      item.Modic > 0 ||
      item.diskPat > 0 ||
      item.yellowLigament > 0 ||
      item.foramenR + item.foramenL > 0 ||
      item.recessR + item.recessL > 0 ||
      item.facetArthrosisR + item.facetArthrosisL > 0 ||
      item.uncoArthrosisR + item.uncoArthrosisL > 0 ||
      item.spinalCanal > 0 ||
      compressions.filter(compression => compression.vertebra === item.value)
        .length > 0 ||
      boneLesions.filter(boneLesion => boneLesion.vertebra === item.value)
        .length > 0 ||
      laminectomy.filter(laminectomy => laminectomy.segment === item.value)
        .length > 0 ||
      stabilisation.filter(
        stabilisation => stabilisation.vertebraFirst === item.value
      ).length > 0 ||
      customSegment.filter(
        customSegment => customSegment.segment === item.value
      ).length > 0 ||
      customVertebra.filter(
        customVertebra => customVertebra.vertebra === item.value
      ).length > 0
  );

  let arthrosisDecompArray = [];
  segments.map(item => {
    // dekompenzace artrózy
    arthrosisDecompConclusion = '';
    let facetArthrosis = '';
    let uncoArthrosis = '';
    if (
      item.facetArthrosisR === 2 ||
      item.facetArthrosisL === 2 ||
      item.uncoArthrosisR === 2 ||
      item.uncoArthrosisL === 2
    ) {
      // Facetová artróza
      if (item.facetArthrosisR === 2 || item.facetArthrosisL === 2) {
        if (item.facetArthrosisR === 2 && item.facetArthrosisL === 2) {
          facetArthrosis = 'bilat. facetové artrózy';
        } else {
          if (item.facetArthrosisR === 2) {
            facetArthrosis = 'pravostranné facetové artrózy';
          }
          if (item.facetArthrosisL === 2) {
            facetArthrosis = 'levostranné facetové artrózy';
          }
        }
        facetArthrosis += ' ' + item.name;
        arthrosisDecompArray.push(facetArthrosis);
      }

      // Unkovertebrální artróza
      if (item.uncoArthrosisR === 2 || item.uncoArthrosisL === 2) {
        if (item.uncoArthrosisR === 2 && item.uncoArthrosisL === 2) {
          uncoArthrosis += 'bilat. unkovertebrální artrózy';
        } else {
          if (item.uncoArthrosisR === 2) {
            uncoArthrosis += 'pravostranné unkovertebrální artrózy';
          }
          if (item.uncoArthrosisL === 2) {
            uncoArthrosis += 'levostranné unkovertebrální artrózy';
          }
        }
        uncoArthrosis += ' ' + item.name;
        arthrosisDecompArray.push(uncoArthrosis);
      }
      arthrosisDecompConclusion =
        'Akutní dekompenzace ' + formatArrayToText(arthrosisDecompArray) + '. ';
    }

    // Komprese
    let compressionDescription = '';
    let compressionConclusion = '';
    let compressedVertebra = compressions.filter(
      compression => compression.vertebra === item.value
    );
    if (compressedVertebra.length > 0) {
      let vertebra = compressedVertebra[0];
      compressionDescription =
        aFractureSite[vertebra.area].description +
        aVertebrae[vertebra.vertebra].text +
        aFractureEndplate[vertebra.endplate].description +
        aFractureDepth[vertebra.compression].description +
        aFractureEdema[vertebra.edema].description +
        aFractureProminence[vertebra.prominence].description;
      compressionConclusion =
        aFractureEdema[vertebra.edema].conclusion +
        aFractureDepth[vertebra.compression].conclusion +
        aFractureSite[vertebra.area].conclusion +
        aFractureEndplate[vertebra.endplate].conclusion +
        'obratlového těla ' +
        aVertebrae[vertebra.vertebra].text +
        aFractureProminence[vertebra.prominence].conclusion;
    }

    // Ložiska
    let boneLesionDescription = '';
    let boneLesionVertebra = boneLesions.filter(
      boneLesion => boneLesion.vertebra === item.value
    );
    if (boneLesionVertebra.length > 0) {
      let vertebra = boneLesionVertebra[0];
      boneLesionDescription =
        'ložisko v ' +
        aVertebrae[vertebra.vertebra].text +
        ' ' +
        aSignal[vertebra.T2].description +
        'T2, ' +
        aSignal[vertebra.T1].description +
        'T1, ' +
        aSignal[vertebra.STIR].description +
        'STIR, ' +
        (vertebra.size !== '' ? 'velikosti ' + vertebra.size + 'mm, ' : '') +
        vertebra.description;
      boneLesionsConclusion +=
        'Ložisko v ' +
        aVertebrae[vertebra.vertebra].text +
        (vertebra.size !== '' ? ' (' + vertebra.size + 'mm)' : '') +
        ' - ' +
        vertebra.conclusion;
    }

    // Laminektomie
    laminectomyDescription = '';
    laminectomyConclusion = '';
    let laminectomySegment = laminectomy.filter(
      segment => segment.segment === item.value
    );

    if (laminectomySegment.length > 0) {
      let segment = laminectomySegment[0];
      laminectomyDescription =
        aLaminectomy[segment.side].description +
        (segment.description !== ''
          ? ' - ' + segment.description + ', '
          : ', ');
      laminectomyConclusion =
        aLaminectomy[segment.side].conclusion +
        (segment.conclusion !== ''
          ? ' - ' + segment.conclusion + ', '
          : ' s přiměřeným nálezem, ');
    }

    // Stabilizace
    let stabilisationDescription = '';
    let stabilisationSegment = stabilisation.filter(
      segment => segment.vertebraFirst === item.value
    );

    if (stabilisationSegment.length > 0) {
      let segment = stabilisationSegment[0];
      stabilisationDescription =
        aStabilisation[segment.type].description +
        aVertebrae[segment.vertebraFirst].text +
        (segment.vertebraFirst < segment.vertebraLast
          ? ' až ' + aVertebrae[segment.vertebraLast].text
          : '') +
        (segment.description !== ''
          ? ', ' + segment.description + ', '
          : ',  ');
      stabilisationConclusion +=
        aStabilisation[segment.type].conclusion +
        aVertebrae[segment.vertebraFirst].text +
        (segment.vertebraFirst < segment.vertebraLast
          ? ' až ' + aVertebrae[segment.vertebraLast].text
          : '') +
        (segment.conclusion !== ''
          ? ' - ' + segment.conclusion + '. '
          : ' s přiměřeným nálezem. ');
    }

    // Vlastní segment
    let customSegmentDescription = '';
    let customSeg = customSegment.filter(
      segment => segment.segment === item.value
    );

    if (customSeg.length > 0) {
      let segment = customSeg[0];
      customSegmentDescription = segment.description + ', ';
      customSegmentConclusion = segment.conclusion + ', ';
    } else {
      customSegmentConclusion = customSegmentDescription = '';
    }

    // Vlastní obratel
    let customVertebraDescription = '';
    let customVert = customVertebra.filter(
      vertebra => vertebra.vertebra === item.value
    );

    if (customVert.length > 0) {
      let vertebra = customVert[0];
      customVertebraDescription = vertebra.description + ', ';
      customVertebraConclusion += vertebra.conclusion;
    }

    let directions = item.diskDirection.map(direction => direction.symbol);
    let directionsText = getDiskDirection(directions);

    let facetUncoArthrosisDescription = '';
    if (
      item.facetArthrosisR +
        item.facetArthrosisL +
        item.uncoArthrosisR +
        item.uncoArthrosisL >
      0
    ) {
      facetUncoArthrosisDescription = '';
      let arthrosisOnly = [];
      let arthrosisAndEdema = [];

      // facetová artróza
      if (item.facetArthrosisR > 0 && item.facetArthrosisL > 0) {
        arthrosisOnly.push('oba facetové klouby');
        if (item.facetArthrosisR == 2 && item.facetArthrosisL == 2) {
          arthrosisAndEdema.push('oba facetové klouby');
        } else if (item.facetArthrosisR == 2) {
          arthrosisAndEdema.push('pravý facetový kloub');
        } else if (item.facetArthrosisL == 2) {
          arthrosisAndEdema.push('levý facetový kloub');
        }
      } else if (item.facetArthrosisR > 0) {
        arthrosisOnly.push('pravý facetový kloub');
        if (item.facetArthrosisR == 2) {
          arthrosisAndEdema.push('pravý facetový kloub');
        }
      } else if (item.facetArthrosisL > 0) {
        arthrosisOnly.push('levý facetový kloub');
        if (item.facetArthrosisL == 2) {
          arthrosisAndEdema.push('levý facetový kloub');
        }
      }

      // unkovertebrální artróza
      if (item.uncoArthrosisR > 0 && item.uncoArthrosisL > 0) {
        arthrosisOnly.push('oba unkovertebrální klouby');
        if (item.uncoArthrosisR === 2 && item.uncoArthrosisL == 2) {
          arthrosisAndEdema.push('oba unkovertebrální klouby');
        } else if (item.uncoArthrosisR === 2) {
          arthrosisAndEdema.push('pravý unkovertebrální kloub');
        } else if (item.uncoArthrosisL === 2) {
          arthrosisAndEdema.push('levý unkovertebrální kloub');
        }
      } else if (item.uncoArthrosisR > 0) {
        arthrosisOnly.push('pravý unkovertebrální kloub');
        if (item.uncoArthrosisR === 2) {
          arthrosisAndEdema.push('pravý unkovertebrální kloub');
        }
      } else if (item.uncoArthrosisL > 0) {
        arthrosisOnly.push('levý unkovertebrální kloub');
        if (item.uncoArthrosisL === 2) {
          arthrosisAndEdema.push('levý unkovertebrální kloub');
        }
      }

      // kompletace facetové a unkovertebrální artrózy
      facetUncoArthrosisDescription +=
        formatArrayToText(arthrosisOnly) + aFacetArthrosis[1].description;
      if (arthrosisAndEdema.length > 0) {
        facetUncoArthrosisDescription +=
          formatArrayToText(arthrosisAndEdema) + aFacetArthrosis[2].description;
      }
    }

    let causeOfForaminalStenosis = [];
    if (item.uncoArthrosisR + item.uncoArthrosisL > 0)
      causeOfForaminalStenosis.push('unkovertebrální');
    if (item.facetArthrosisR + item.facetArthrosisL > 0)
      causeOfForaminalStenosis.push('facetovou');

    if (causeOfForaminalStenosis.length > 0)
      causeOfForaminalStenosis =
        formatArrayToText(causeOfForaminalStenosis) + ' artrózou, ';

    let segmentDescription =
      item.listesis +
        item.diskDegen +
        item.diskPat +
        item.yellowLigament +
        item.uncoArthrosisR +
        item.uncoArthrosisL +
        item.facetArthrosisR +
        item.facetArthrosisL +
        item.foramenR +
        item.foramenL +
        item.Modic +
        item.spinalCanal >
        0 ||
      customSegmentDescription !== '' ||
      compressionDescription !== '' ||
      laminectomyDescription !== '' ||
      arthrosisDecompConclusion !== ''
        ? item.name +
          ' - ' +
          customSegmentDescription +
          compressionDescription +
          laminectomyDescription +
          (item.spinalCanal > 0
            ? aStenosis[item.spinalCanal].canalLSConclusion +
              (item.diskPat > 0 && item.diskPat !== 6
                ? aDiskPat[item.diskPat].conclusion
                : '') +
              directionsText +
              (item.diskPatSize > 0
                ? ' (' + aSize[item.diskPatSize].text + ')'
                : '') +
              (item.diskMigration > 0
                ? ' ' + aDiskMigration[item.diskMigration].description
                : '') +
              (item.diskMigrationSize > 0 && item.diskMigration > 0
                ? ' (' + aSize[item.diskMigrationSize].text + ')'
                : '') +
              (item.diskPat > 0 &&
              (item.listesis > 0 || item.yellowLigament > 0)
                ? ' a '
                : '') +
              (item.listesis > 0 || item.yellowLigament > 0
                ? 'degenerativních změn (' +
                  (item.listesis > 0
                    ? aListesis[item.listesis].conclusion +
                      getFirstVertebraFromSegment(item.name) +
                      (item.listesisSize > 0
                        ? ' o ' + aSize[item.listesisSize].text
                        : '')
                    : '') +
                  (item.listesis > 0 && item.yellowLigament > 0 ? ', ' : '') +
                  (item.yellowLigament > 0 ? 'hypertrofie žlutých vazů' : '') +
                  ')'
                : '') +
              ', '
            : // bez stenózy páteřního kanálu
              (item.diskPat > 0 && item.diskPat !== 6
                ? aDiskPat[item.diskPat].description +
                  directionsText +
                  (item.diskPatSize > 0
                    ? ' (' + aSize[item.diskPatSize].text + ')'
                    : '') +
                  (item.diskMigration > 0 ? ' ' : ', ') +
                  (item.diskMigration > 0
                    ? aDiskMigration[item.diskMigration].description
                    : '') +
                  (item.diskMigrationSize > 0 && item.diskMigration > 0
                    ? ' (' + aSize[item.diskMigrationSize].text + '), '
                    : '')
                : '') +
              (item.diskPat === 6
                ? 'anulární fisura disku ' + directionsText + ', '
                : '') +
              (item.listesis > 0
                ? aListesis[item.listesis].conclusion +
                  getFirstVertebraFromSegment(item.name) +
                  (item.listesisSize > 0
                    ? ' (' + aSize[item.listesisSize].text + '), '
                    : '')
                : '') +
              (item.yellowLigament > 0 ? 'hypertrofie žlutých vazů, ' : '') +
              (item.uncoArthrosisR + item.uncoArthrosisL > 0 &&
              item.foramenR + item.foramenL === 0
                ? item.uncoArthrosisR > 0 && item.uncoArthrosisL > 0
                  ? 'bilat. unkovertebrální artróza, '
                  : item.uncoArthrosisR > 0
                  ? 'pravostranná unkovertebrální artróza, '
                  : 'levostranná unkovertebrální artróza, '
                : '') +
              (item.facetArthrosisR + item.facetArthrosisL > 0 &&
              item.foramenR + item.foramenL === 0
                ? item.facetArthrosisR > 0 && item.facetArthrosisL > 0
                  ? 'bilat. facetová artróza, '
                  : item.facetArthrosisR > 0
                  ? 'pravostranná facetová artróza, '
                  : 'levostranná facetová artróza, '
                : '')) +
          // dekomp. unkovertebrální a facetové artrózy
          (item.uncoArthrosisR === 2 || item.uncoArthrosisL === 2
            ? 'edém kostní dřeně ' +
              (item.uncoArthrosisR === 2 && item.uncoArthrosisL === 2
                ? 'obou unkovertebrálních kloubů'
                : item.uncoArthrosisR === 2
                ? 'pravého unkovertebrálního kloubu'
                : item.uncoArthrosisL === 2
                ? 'levého unkovertebrálního kloubu'
                : '')
            : '') +
          (item.facetArthrosisR === 2 || item.facetArthrosisL === 2
            ? 'edém kostní dřeně ' +
              (item.facetArthrosisR === 2 && item.facetArthrosisL === 2
                ? 'obou facetových kloubů'
                : item.facetArthrosisR === 2
                ? 'pravého facetového kloubu'
                : item.facetArthrosisL === 2
                ? 'levého facetového kloubu'
                : '')
            : '') +
          (item.foramenR > 0 || item.foramenL > 0
            ? item.foramenR === item.foramenL
              ? aStenosis[item.foramenR].foramenLSConclusion +
                'stenóza obou foramin '
              : item.foramenR > 0 && item.foramenL > 0
              ? aStenosis[item.foramenR].foramenLSConclusion +
                'stenóza pravého a ' +
                aStenosis[item.foramenL].foramenLSConclusion +
                'stenóza levého foramina '
              : item.foramenR > 0
              ? aStenosis[item.foramenR].foramenLSConclusion +
                'stenóza pravého foramina '
              : aStenosis[item.foramenL].foramenLSConclusion +
                'stenóza levého foramina '
            : '') +
          (item.foramenR > 0 || item.foramenL > 0
            ? causeOfForaminalStenosis !== ''
              ? causeOfForaminalStenosis
              : ', '
            : '') +
          aDiskDegen[item.diskDegen].conclusion +
          aModic[item.Modic].description
        : '';
    importDescription1(segmentDescription);
  });

  // Zkrácený závěr páteře
  // Stenóza páteřního kanálu
  let mildStenosis = [];
  let moderateStenosis = [];
  let severeStenosis = [];

  let spinalCanalStenosisConclusion = '';

  mildStenosis = segments.filter(segment => segment.spinalCanal === 1);
  moderateStenosis = segments.filter(segment => segment.spinalCanal === 2);
  severeStenosis = segments.filter(segment => segment.spinalCanal === 3);

  function stenosisCause(segment) {
    let aCauses = [];
    if (segment.listesis == 1) {
      aCauses.push('ventrolistézou');
    } else if (segment.listesis == 2) {
      aCauses.push('dorzolistézou');
    }
    if (segment.diskPat == 1) {
      aCauses.push('osteofyt. disk. komplexem');
    } else if (segment.diskPat == 2) {
      aCauses.push('hernií disku');
    } else if (segment.diskPat == 3) {
      aCauses.push('osteofyty');
    }
    if (segment.yellowLigament > 0) {
      aCauses.push('hypertrofií žl. vazů');
    }
    return aCauses.length > 0 ? ' (' + aCauses.join(', ') + ')' : '';
  }

  if (
    severeStenosis.length > 0 &&
    moderateStenosis.length > 0 &&
    mildStenosis.length > 0
  ) {
    spinalCanalStenosisConclusion +=
      'Těžká stenóza páteřního kanálu se susp. myelopatií v úrovni ' +
      formatArrayToText(
        severeStenosis.map(item => item.name + stenosisCause(item))
      ) +
      ', středně těžká (bez myelopatie) v úrovni ' +
      formatArrayToText(
        moderateStenosis.map(item => item.name + stenosisCause(item))
      ) +
      ' a lehká v úrovni ' +
      formatArrayToText(
        mildStenosis.map(item => item.name + stenosisCause(item))
      ) +
      '. ';
  } else if (moderateStenosis.length > 0 && mildStenosis.length > 0) {
    spinalCanalStenosisConclusion +=
      'Středně těžká stenóza páteřního kanálu (bez myelopatie) v úrovni ' +
      formatArrayToText(
        moderateStenosis.map(item => item.name + stenosisCause(item))
      ) +
      ' a lehká v úrovni ' +
      formatArrayToText(
        mildStenosis.map(item => item.name + stenosisCause(item))
      ) +
      '. ';
  } else if (severeStenosis.length > 0 && mildStenosis.length > 0) {
    spinalCanalStenosisConclusion +=
      'Těžká stenóza páteřního kanálu se susp. myelopatií v úrovni ' +
      formatArrayToText(
        severeStenosis.map(item => item.name + stenosisCause(item))
      ) +
      ' a lehká v úrovni ' +
      formatArrayToText(
        mildStenosis.map(item => item.name + stenosisCause(item))
      ) +
      '. ';
  } else if (severeStenosis.length > 0 && moderateStenosis.length > 0) {
    spinalCanalStenosisConclusion +=
      'Těžká stenóza páteřního kanálu se susp. myelopatií v úrovni ' +
      formatArrayToText(
        severeStenosis.map(item => item.name + stenosisCause(item))
      ) +
      ' a středně těžká (bez myelopatie) v úrovni ' +
      formatArrayToText(
        moderateStenosis.map(item => item.name + stenosisCause(item))
      ) +
      '. ';
  } else {
    if (mildStenosis.length > 0) {
      spinalCanalStenosisConclusion +=
        'Lehká stenóza páteřního kanálu v úrovni ' +
        formatArrayToText(
          mildStenosis.map(item => item.name + stenosisCause(item))
        ) +
        '. ';
    } else if (moderateStenosis.length > 0) {
      spinalCanalStenosisConclusion +=
        'Středně těžká stenóza páteřního kanálu (bez myelopatie) v úrovni ' +
        formatArrayToText(
          moderateStenosis.map(item => item.name + stenosisCause(item))
        ) +
        '. ';
    } else if (
      severeStenosis.length + moderateStenosis.length + mildStenosis.length >
      0
    ) {
      spinalCanalStenosisConclusion +=
        'Těžká stenóza páteřního kanálu se susp. myelopatií v úrovni ' +
        formatArrayToText(
          severeStenosis.map(item => item.name + stenosisCause(item))
        ) +
        '. ';
    }
  }

  // Stenóza foramin
  let foramenStenosisSevere = [];
  let foramenStenosisModerate = [];
  let foramenStenosisMild = [];

  foramenStenosisSevere = segments
    .map(segment => {
      if (segment.foramenR === 3 && segment.foramenL === 3) {
        return 'obou foramin ' + segment.name;
      } else if (segment.foramenR === 3) {
        return 'pravého foramen ' + segment.name;
      } else if (segment.foramenL === 3) {
        return 'levého foramen ' + segment.name;
      }
    })
    .filter(n => n);

  foramenStenosisModerate = segments
    .map(segment => {
      if (segment.foramenR === 2 && segment.foramenL === 2) {
        return 'obou foramin ' + segment.name;
      } else if (segment.foramenR === 2) {
        return 'pravého foramen ' + segment.name;
      } else if (segment.foramenL === 2) {
        return 'levého foramen ' + segment.name;
      }
    })
    .filter(n => n);

  foramenStenosisMild = segments
    .map(segment => {
      if (segment.foramenR === 1 && segment.foramenL === 1) {
        return 'obou foramin ' + segment.name;
      } else if (segment.foramenR === 1) {
        return 'pravého foramen ' + segment.name;
      } else if (segment.foramenL === 1) {
        return 'levého foramen ' + segment.name;
      }
    })
    .filter(n => n);

  let foramenStenosisConclusion = '';
  let foramenArray = [];

  if (foramenStenosisSevere.length > 0) {
    foramenArray.push(
      'Těžká stenóza ' + formatArrayToText(foramenStenosisSevere)
    );
    if (foramenStenosisModerate.length > 0) {
      foramenArray.push(
        'středně těžká ' + formatArrayToText(foramenStenosisModerate)
      );
    }
    if (foramenStenosisMild.length > 0) {
      foramenArray.push('lehká ' + formatArrayToText(foramenStenosisMild));
    }
    foramenStenosisConclusion = formatArrayToText(foramenArray) + '. ';
  } else if (foramenStenosisModerate.length > 0) {
    foramenArray.push(
      'Středně těžká stenóza ' + formatArrayToText(foramenStenosisModerate)
    );
    if (foramenStenosisMild.length > 0) {
      foramenArray.push('lehká ' + formatArrayToText(foramenStenosisMild));
    }
    foramenStenosisConclusion = formatArrayToText(foramenArray) + '. ';
  } else if (foramenStenosisMild.length > 0) {
    foramenArray.push(
      'Lehká stenóza ' + formatArrayToText(foramenStenosisMild)
    );
    foramenStenosisConclusion = formatArrayToText(foramenArray) + '. ';
  }

  // Hernie disků
  let herniasConclusion = '';
  let allHernias = segments
    .map(segment => {
      if (segment.diskPat === 2 && segment.spinalCanal === 0) {
        let directions = segment.diskDirection.map(
          direction => direction.symbol
        );
        return (
          segment.name +
          ' ' +
          getDiskDirection(directions) +
          ' (' +
          segment.diskPatSize +
          'mm' +
          (segment.diskMigration > 0
            ? ', ' + aDiskMigration[segment.diskMigration].description
            : '') +
          (segment.diskMigrationSize > 0
            ? ' ' + aSize[segment.diskMigrationSize].text + ')'
            : ')')
        );
      }
    })
    .filter(n => n);

  if (allHernias.length > 0) {
    herniasConclusion = 'Hernie disku ' + formatArrayToText(allHernias) + '. ';
  }

  // Anulární fisury
  let anularFissureConclusion = '';
  let allAnularFissures = segments
    .map(segment => {
      if (segment.diskPat === 4) {
        let directions = segment.diskDirection.map(
          direction => direction.symbol
        );
        return segment.name + ' ' + getDiskDirection(directions);
      }
    })
    .filter(n => n);

  if (allAnularFissures.length > 0) {
    anularFissureConclusion =
      'Anulární fisura disku ' + formatArrayToText(allAnularFissures) + '. ';
  }

  // Listézy
  let listhesisConclusion = '';
  let allVentrolisthesis = segments
    .map(segment => {
      if (segment.listesis === 1) {
        return (
          getFirstVertebraFromSegment(segment.name) +
          ' (' +
          aSize[segment.listesisSize].text +
          ')'
        );
      }
    })
    .filter(n => n);

  let allDorsolisthesis = segments
    .map(segment => {
      if (segment.listesis === 2) {
        return (
          getFirstVertebraFromSegment(segment.name) +
          ' (' +
          aSize[segment.listesisSize].text +
          ')'
        );
      }
    })
    .filter(n => n);

  if (allVentrolisthesis.length + allDorsolisthesis.length > 0) {
    if (allVentrolisthesis.length > 0) {
      listhesisConclusion +=
        'Ventrolistéza ' + formatArrayToText(allVentrolisthesis) + '. ';
    }
    if (allDorsolisthesis.length > 0) {
      listhesisConclusion +=
        'Dorzolistéza ' + formatArrayToText(allDorsolisthesis) + '. ';
    }
  }

  // Akutní dekomp. degenerativních změn
  // // Modic 1
  let decompDegenPlates = segments
    .map(segment => {
      if (segment.Modic === 1) {
        return segment.name;
      }
    })
    .filter(n => n);

  let decompFacetArthrosis = segments
    .map(segment => {
      if (segment.facetArthrosisR === 2 && segment.facetArthrosisL === 2) {
        return segment.name + ' bilat.';
      } else if (segment.facetArthrosisR === 2) {
        return segment.name + ' vpravo';
      } else if (segment.facetArthrosisL === 2) {
        return segment.name + ' vlevo';
      }
    })
    .filter(n => n);

  let decompUncoArthrosis = segments
    .map(segment => {
      if (segment.uncoArthrosisR === 2 && segment.uncoArthrosisL === 2) {
        return segment.name + ' bilat.';
      } else if (segment.uncoArthrosisR === 2) {
        return segment.name + ' vpravo';
      } else if (segment.uncoArthrosisL === 2) {
        return segment.name + ' vlevo';
      }
    })
    .filter(n => n);

  let decompDegenPlatesFormatted =
    decompDegenPlates.length > 0
      ? 'degen. změn krycích ploten ' + formatArrayToText(decompDegenPlates)
      : null;

  let decompFacetArthrosisFormatted =
    decompFacetArthrosis.length > 0
      ? 'facetové artrózy ' + formatArrayToText(decompFacetArthrosis)
      : null;

  let decompUncoArthrosisFormatted =
    decompUncoArthrosis.length > 0
      ? 'unkovertebrální artrózy ' + formatArrayToText(decompUncoArthrosis)
      : null;

  let decompArray = [];
  if (decompDegenPlates.length > 0)
    decompArray.push(decompDegenPlatesFormatted);
  if (decompFacetArthrosis.length > 0)
    decompArray.push(decompFacetArthrosisFormatted);
  if (decompUncoArthrosis.length > 0)
    decompArray.push(decompUncoArthrosisFormatted);

  let acuteDecompDegenConclusion =
    decompArray.length > 0
      ? 'Akutní dekompenzace ' + formatArrayToText(decompArray) + '. '
      : '';

  // Páteř obecně
  const {
    spineSag,
    spineCor,
    hemangiomas,
    schmorls,
    addFinds,
    spineCustomDescription,
    spineCustomConclusion,
  } = props.general;

  let hemangiomasFinal = hemangiomas
    .sort(compareNumbers)
    .map(item => aVertebrae[item].text);
  hemangiomasFinal = formatArrayToText(hemangiomasFinal);

  let spineGeneralDescription =
    spineCustomDescription +
    aSpineCor[spineCor].description +
    (segments.filter(item => item.listesis > 0).length > 0
      ? 'Jinak plynulá krční lordóza. '
      : aSpineSag[spineSag].description) +
    (compressions.length > 0
      ? 'Jinak přiměřená výše obratlových těl. '
      : 'Přiměřená výše obratlových těl. ') +
    'Mícha v krčním úseku pravidelné šíře, bez strukturálních či signálových změn. ' +
    aADArthrosis[ADArthrosis].description +
    (hemangiomas.length > 0
      ? 'Ložisko na T2 vyšší SI a na T1 bez snížení SI v kostní dřeni obratlového těla ' +
        hemangiomasFinal +
        '. '
      : '') +
    (hemangiomas.length > 0 || boneLesions.length > 0
      ? 'Jinak bez ložiskových změn skeletu. '
      : 'Bez ložiskových změn skeletu. ');

  let spineGeneralConclusion =
    aSpineCor[spineCor].conclusion +
    aSpineSag[spineSag].conclusion +
    aADArthrosis[ADArthrosis].conclusion +
    (hemangiomas.length > 0
      ? 'Hemangiom obratle ' + hemangiomasFinal + '. '
      : '') +
    spineCustomConclusion;

  // Páteř ostatní
  const aAllSegments = props.segments;
  schmorls.sort(compareNumbers);
  let schmorlsFinal = schmorls.map(item => aAllSegments[item].name);
  schmorlsFinal = formatArrayToText(schmorlsFinal);

  let spineOtherDescription =
    (schmorls.length > 0
      ? 'Povrchové defekty centrální části krycích ploten segmentu ' +
        schmorlsFinal +
        ' obrazu Schmorlových uzlů. '
      : '') +
    (addFinds.length > 0
      ? addFinds
          .sort(compareNumbers)
          .map(item => aAddFinds[item].description)
          .join('')
      : '');
  let spineOtherConclusion =
    addFinds.length > 0
      ? 'Vedlejší nález: ' +
        formatArrayToText(
          addFinds.sort(compareNumbers).map(item => aAddFinds[item].conclusion)
        )
      : '';

  let finalConclusion =
    spinalCanalStenosisConclusion +
    foramenStenosisConclusion +
    herniasConclusion +
    anularFissureConclusion +
    listhesisConclusion +
    acuteDecompDegenConclusion;

  // Zkrácený závěr
  importConclusion1(
    (segments.length > 0
      ? finalConclusion !== ''
        ? finalConclusion
        : 'Nevýrazné degenerativní změny C páteře. '
      : 'Přiměřený nález na MR C páteře. ') +
      customVertebraConclusion +
      stabilisationConclusion +
      spineGeneralConclusion +
      boneLesionsConclusion +
      spineOtherConclusion
  );

  importDescription2(spineGeneralDescription + spineOtherDescription);

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
